import api from "@/api/units.js";
import { i18n } from "@/plugins/i18n";
import translateErrors from "@/store/utils/translateErrors";

const state = {
  list: { result: [] },
  item: null,
  isLoading: false,
  isCreating: false,
  isUploading: false,
  isDeleting: false,
  isRegaRefresh: false,
  isCreateUnitRega: false,
  errors: [],
  units: [],
  regaQr: {},
};

const mutations = {
  getListUnitStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getListUnitSuccess(state, payload) {
    state.isLoading = false;
    state.list = payload;
    state.errors = [];
  },
  getListUnitFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getAllUnitsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getAllUnitsSuccess(state, payload) {
    state.isLoading = false;
    state.units = payload;
    state.errors = [];
  },
  getAllUnitsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createItemUnitStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createItemUnitSuccess(state) {
    state.isCreating = false;
    state.errors = [];
  },
  createItemUnitFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  addMediaUnitStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addMediaUnitSuccess(state) {
    state.isUploading = false;
    // state.item = payload;
    state.errors = [];
  },
  addMediaUnitFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },

  removeProjectUnitAdditionalFile(state, payload) {
    state.item = {
      ...state.item,
      file_attachments: state.item.file_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  removeProjectUnitDesignFile(state, payload) {
    state.item = {
      ...state.item,
      design_attachments: state.item.design_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  removeProjectUnitMediaFile(state, payload) {
    state.item = {
      ...state.item,
      media_attachments: state.item.media_attachments.filter(
        (file) => file.id !== payload
      ),
    };
  },

  getItemUnitStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getItemUnitSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
    state.errors = [];
  },
  getItemUnitFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateItemUnitStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  updateItemUnitSuccess(state, payload) {
    state.isLoading = false;
    state.item = payload;
    state.errors = [];
  },
  updateItemUnitFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  removeItemUnitStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  removeItemUnitSuccess(state) {
    state.isUploading = false;
    state.errors = [];
  },
  removeItemUnitFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },

  actionItemUnitStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  actionItemUnitSuccess(state, payload) {
    state.isUploading = false;
    state.item = payload;
    state.errors = [];
  },
  actionItemUnitFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },

  removeItemUnitFileStart(state) {
    state.isDeleting = true;
    state.errors = [];
  },
  removeItemUnitFileSuccess(state) {
    state.isDeleting = false;
    state.errors = [];
  },
  removeItemUnitFileFailed(state, payload) {
    state.isDeleting = false;
    state.errors = payload;
  },
  resetErrorsProjectUnits() {
    state.errors = []
  },
  writeProjectUnitItemRegaQr(state, payload) {
    state.regaQr = payload;
  },
  refreshRegaStart(state) {
    state.isRegaRefresh = true;
    state.errors = [];
  },
  refreshRegaSuccess(state) {
    state.isRegaRefresh = false;
    state.errors = [];
  },
  refreshRegaFailed(state, payload) {
    state.isRegaRefresh = false;
    state.errors = payload;
  },
  createUnitRegaStart(state) {
    state.isCreateUnitRega = true;
    state.errors = [];
  },
  createUnitRegaSuccess(state) {
    state.isCreateUnitRega = false;
    state.errors = [];
  },
  createUnitRegaFailed(state, payload) {
    console.log('payload', payload)
    state.isCreateUnitRega = false;
    state.errors = payload;
  },
};
const actions = {
  async getProjectUnitList(context, { id, data }) {
    try {
      context.commit("getListUnitStart");

      const res = await api.getUnitsList(id, data);
      const answer = res.data;
      for (let i = 0; i < res.data.result.length; i++) {
        answer.result[i].numberOld = res.data.result[i].number;
        answer.result[i].floorOld = res.data.result[i].floor;

        answer.result[i].deed_numberOld = res.data.result[i].deed_number;
        const withoutCents = res.data.result[i].price / 100;
        answer.result[i].price = withoutCents;
        answer.result[i].priceOld = withoutCents;
        answer.result[i].land_total_price = res.data.result[i].land_total_price / 100;
        answer.result[i].land_total_annual_rent = res.data.result[i].land_total_annual_rent / 100;
      }
      context.commit("getListUnitSuccess", answer);

      return true;
    } catch (e) {
      context.commit("getListUnitFailed", e?.response?.data?.error || []);

      return false;
    }
  },

  async getAllUnits(context, payload) {
    try {
      context.commit("getAllUnitsStart");

      const res = await api.getAllUnits(payload);
      context.commit("getAllUnitsSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getAllUnitsFailed", e?.response?.data?.error);

      return false;
    }
  },

  async getProjectUnitItemRegaQr(context, payload) {
    try {
      const res = await api.getUnitItemRegaQr(payload);
      context.commit("writeProjectUnitItemRegaQr", res.data);

      return true;
    } catch (e) {
      context.commit("getItemUnitFailed", e?.response?.data?.error || []);
      return false;
    }
  },

  async resetProjectUnitItemRegaQr(context) {
    context.commit("writeProjectUnitItemRegaQr", {});
  },

  async getProjectUnitItem(context, payload) {
    try {
      context.commit("getItemUnitStart");

      const res = await api.getUnitItem(payload);
      context.commit("getItemUnitSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getItemUnitFailed", e?.response?.data?.error || []);

      return false;
    }
  },

  async createProjectUnitItem(context, { id, data }) {
    try {
      context.commit("createItemUnitStart");

      const res = await api.createUnitItem(id, data);
      context.commit("createItemUnitSuccess");

      return res.data.number;
    } catch (e) {
      context.commit("createItemUnitFailed", e?.response?.data?.error);

      return false;
    }
  },

  async updateProjectUnitItem(context, { id, uId, data, step }) {
    try {
      context.commit("updateItemUnitStart");
      let res = {}

      if (!step) {
        res = await api.updateUnitItem(id, uId, data);
        const _data = {
          holoul_listed: data.holoul_listed.length,
          agency_website_listed: data.agency_website_listed.length
        }
        res = await api.updateUnitItemListing(id, uId, _data);
      }

      if (step === 'constraints') {
        res = await api.updateUnitItemConstraints(id, uId, data);
      }

      if (step === 'address') {
        res = await api.updateUnitItemAddress(id, uId, data);
      }

      res.data.numberOld = res.data.number;
      res.data.floorOld = res.data.floor;

      res.data.deed_numberOld = res.data.deed_number;
      const priceWithoutCents = res.data.price / 100;
      res.data.price = priceWithoutCents;
      res.data.priceOld = priceWithoutCents;
      res.data.land_total_price = res.data.land_total_price / 100;
      res.data.land_total_annual_rent = res.data.land_total_annual_rent / 100;
      context.commit("updateItemUnitSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateItemUnitFailed", e?.response?.data?.error);

      return false;
    }
  },

  async addProjectUnitMedia(context, { id, uId, data }) {
    try {
      context.commit("addMediaUnitStart");
      const requests = data.map((file) => api.addUnitItemFile(id, uId, file));
      const res = await Promise.all(requests)
      context.commit("addMediaUnitSuccess", res[res.length - 1].data);

      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaUnitFailed", e?.response?.data?.error);
      return false;
    }
  },

  async removeProjectUnit(context, { id, uId }) {
    try {
      context.commit("removeItemUnitStart");

      await api.removeUnitItem(id, uId);
      context.commit("removeItemUnitSuccess");
      return true;
    } catch (e) {
      context.commit("removeItemUnitFailed", e?.response?.data?.error);
      return false;
    }
  },

  async actionProjectUnit(context, { id, uId, data }) {
    try {
      context.commit("actionItemUnitStart");

      const res = await api.actionUnitItem(id, uId, data);
      res.data.numberOld = res.data.number;
      res.data.floorOld = res.data.floor;

      res.data.deed_numberOld = res.data.deed_number;
      const priceWithoutCents = res.data.price / 100;
      res.data.price = priceWithoutCents;
      res.data.priceOld = priceWithoutCents;
      res.data.land_total_price = res.data.land_total_price / 100;
      res.data.land_total_annual_rent = res.data.land_total_annual_rent / 100;
      context.commit("actionItemUnitSuccess", res.data);

      return { res: true, errors: null };
    } catch (e) {
      context.commit("actionItemUnitFailed", e?.response?.data?.error);
      const allErrors= {
        "design__not_selected": "designErrors",
        "number__not_filled": "numberErrors",
        "deed_number__not_filled": "deedNumberErrors",
        "price__not_filled": "priceErrors"
      }
      const error = String(e?.response?.data?.error[0])

      return { res: false, errors: {[allErrors[error]]: i18n.t(`errors.${error}`)}}
    }
  },

  async removeProjectUnitFile(context, { id, uId, fileId }) {
    try {
      context.commit("removeItemUnitFileStart");

      await api.removeUnitItemFile(id, uId, fileId);
      context.commit("removeItemUnitFileSuccess");

      return true;
    } catch (e) {
      context.commit("removeItemUnitFileFailed", e?.response?.data?.error);
      return false;
    }
  },

  async refreshRegaUnit (context, {id, uId, data}) {
    try {
      context.commit("refreshRegaStart");

      await api.regaRefreshUnit(id, uId, data);
      context.commit("refreshRegaSuccess");

      return true;
    } catch (e) {
      context.commit("refreshRegaFailed", e?.response?.data?.error);
      return false;
    }
  },

  async createUnitRega (context, {id, data}) {
    try {
      context.commit("createUnitRegaStart");

      await api.createUnitRega(id, data);
      context.commit("createUnitRegaSuccess");

      return true;
    } catch (e) {
      context.commit("createUnitRegaFailed", e?.response?.data?.error || []);
      return false;
    }
  }
};

const getters = {
  projectBuildingUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "location_latitude__required") &&
      result.push(i18n.t("errors.buildingRequired"));
    errors.find((item) => item === "location_latitude__invalid") &&
      result.push(i18n.t("errors.buildingInvalid"));
    errors.find((item) => item === "location_longitude__required") &&
      result.push(i18n.t("errors.buildingRequired"));
    errors.find((item) => item === "location_longitude__invalid") &&
      result.push(i18n.t("errors.buildingInvalid"));
    errors.find((item) => item === "building__required") &&
      result.push(i18n.t("errors.buildingRequired"));
    errors.find((item) => item === "building__invalid") &&
      result.push(i18n.t("errors.buildingInvalid"));
    return result;
  },
  projectNumberUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "number__required") &&
      result.push(i18n.t("errors.unitNumberRequired"));
    errors.find((item) => item === "number__invalid") &&
      result.push(i18n.t("errors.unitNumberInvalid"));
    errors.find((item) => item === "number__already_exist") &&
      result.push(i18n.t("errors.unitNumberExist"));
    errors.find((item) => item === "number__exist") &&
      result.push(i18n.t("errors.unitNumberExist"));
    return result;
  },
  projectTitleUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((item) => item === "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  projectDeedNumberUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "deed_number__required") &&
      result.push(i18n.t("errors.deedNumberRequired"));
    errors.find((item) => item === "deed_number__invalid") &&
      result.push(i18n.t("errors.deedNumberInvalid"));
    return result;
  },
  projectFloorUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "floor__required") &&
      result.push(i18n.t("errors.floorRequired"));
    errors.find((item) => item === "floor__invalid") &&
      result.push(i18n.t("errors.floorInvalid"));
    return result;
  },
  projectPriceUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "price__required") &&
      result.push(i18n.t("errors.priceRequired"));
    errors.find((item) => item === "price__invalid") &&
      result.push(i18n.t("errors.priceInvalid"));
    return result;
  },
  projectDesignUnitErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "design__required") &&
      result.push(i18n.t("errors.designUnitRequired"));
    errors.find((item) => item === "design__invalid") &&
      result.push(i18n.t("errors.designInvalid"));
    return result;
  },
  projectViewsUnitErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "views__required") &&
      result.push(i18n.t("errors.viewsRequired"));
    errors.find((item) => item === "views__invalid") &&
      result.push(i18n.t("errors.viewsInvalid"));
    return result;
  },
  projectLocationUnitErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "location_latitude__required") &&
      result.push(i18n.t("errors.locationRequired"));
    errors.find((item) => item === "location_latitude__invalid") &&
      result.push(i18n.t("errors.locationInvalid"));
    errors.find((item) => item === "location_longitude__required") &&
      result.push(i18n.t("errors.locationRequired"));
    errors.find((item) => item === "location_longitude__invalid") &&
      result.push(i18n.t("errors.locationInvalid"));
    return result;
  },

  projectRegaLicenseNumberErrors: translateErrors(['ad_license_number', 'advertiser_id', 'project_unit', 'advertisement_id']),

  projectTotalAreaErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "total_area__required") &&
    result.push(i18n.t("errors.totalAreaRequired"));
    errors.find((item) => item === "total_area__invalid") &&
    result.push(i18n.t("errors.totalAreaInvalid"));
    return result;
  },

  projectBedroomsErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "bedrooms__required") &&
    result.push(i18n.t("errors.bedroomsRequired"));
    errors.find((item) => item === "bedrooms__invalid") &&
    result.push(i18n.t("errors.bedroomsInvalid"));
    return result;
  },
  projectBathroomsErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "bathrooms__required") &&
    result.push(i18n.t("errors.bathroomsRequired"));
    errors.find((item) => item === "bathrooms__invalid") &&
    result.push(i18n.t("errors.bathroomsInvalid"));
    return result;
  },
  projectLivingRoomsErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "living_rooms__required") &&
    result.push(i18n.t("errors.livingRoomsRequired"));
    errors.find((item) => item === "living_rooms__invalid") &&
    result.push(i18n.t("errors.livingRoomsInvalid"));
    return result;
  },

  projectFloorsTotalErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "floors_total__required") &&
    result.push(i18n.t("errors.floorsTotalRequired"));
    errors.find((item) => item === "floors_total__invalid") &&
    result.push(i18n.t("errors.floorsTotalInvalid"));
    return result;
  },

  projectUnitNhcRegionErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_region__required") &&
    result.push(i18n.t("errors.regionRequired"));
    errors.find((item) => item === "nhc_region__invalid") &&
    result.push(i18n.t("errors.regionInvalid"));
    errors.find((item) => item === "src__invalid") &&
    result.push(i18n.t("errors.regionInvalid"));
    return result;
  },
  projectUnitNhcCityErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_city__required") &&
    result.push(i18n.t("errors.cityRequired"));
    errors.find((item) => item === "nhc_city__invalid") &&
    result.push(i18n.t("errors.cityInvalid"));
    return result;
  },
  projectUnitNhcDistrictErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_district__required") &&
    result.push(i18n.t("errors.districtRequired"));
    errors.find((item) => item === "nhc_district__invalid") &&
    result.push(i18n.t("errors.districtInvalid"));
    return result;
  },

  projectUnitAddressErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "address__required") &&
    result.push(i18n.t("errors.addressRequired"));
    errors.find((item) => item === "address__invalid") &&
    result.push(i18n.t("errors.addressInvalid"));
    return result;
  },

  projectUnitAuthorizationNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "authorization_number__invalid") && result.push(i18n.t("errors.authorizationNumberInvalid"));
    return result;
  },

  projectUnitBuildingDateErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_date__invalid") && result.push(i18n.t("errors.buildingDateInvalid"));
    return result;
  },

  projectUnitLandAreaErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_area__invalid") && result.push(i18n.t("errors.landAreaInvalid"));
    return result;
  },

  projectUnitBuildingAreaErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_area__invalid") && result.push(i18n.t("errors.buildingAreaInvalid"));
    return result;
  },

  projectUnitOwnerPhoneErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "owner_phone_code__invalid") && result.push(i18n.t("errors.ownerPhoneCodeInvalid"));
    errors.find((item) => item === "owner_phone_code__required") && result.push(i18n.t("errors.ownerPhoneCodeRequired"));
    errors.find((item) => item === "owner_phone_number__invalid") && result.push(i18n.t("errors.ownerPhoneInvalid"));
    errors.find((item) => item === "owner_phone_number__required") && result.push(i18n.t("errors.ownerPhoneRequired"));
    errors.find((item) => item === "owner_phone__invalid") && result.push(i18n.t("errors.ownerPhoneInvalid"));
    errors.find((item) => item === "owner_phone__required") && result.push(i18n.t("errors.ownerPhoneRequired"));
    return result;
  },

  projectUnitObligationsErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_obligations__required") && result.push(i18n.t("errors.obligationsRequired"));
    errors.find((item) => item === "nhc_obligations__invalid") && result.push(i18n.t("errors.obligationsInvalid"));
    return result;
  },

  projectUnitIsConstrainedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_constrained__required") && result.push(i18n.t("errors.isConstrainedRequired"));
    errors.find((item) => item === "nhc_is_constrained__invalid") && result.push(i18n.t("errors.isConstrainedInvalid"));
    return result;
  },

  projectUnitIsPawnedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_pawned__required") && result.push(i18n.t("errors.isPawnedRequired"));
    errors.find((item) => item === "nhc_is_pawned__invalid") && result.push(i18n.t("errors.isPawnedInvalid"));
    return result;
  },

  projectUnitIsHaltedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_halted__required") && result.push(i18n.t("errors.isHaltedRequired"));
    errors.find((item) => item === "nhc_is_halted__required") && result.push(i18n.t("errors.isHaltedInvalid"));
    return result;
  },

  projectUnitIsTestamentErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_testment__required") && result.push(i18n.t("errors.isTransferredRequired"));
    errors.find((item) => item === "nhc_is_testment__invalid") && result.push(i18n.t("errors.isTransferredInvalid"));
    return result;
  },

  projectUnitDeedTypeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_title_deed_type__invalid") && result.push(i18n.t("errors.deedTypeInvalid"));
    return result;
  },

  projectUnitNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_street_width__invalid") && result.push(i18n.t("errors.streetWidthInvalid"));
    return result;
  },

  projectUnitLandNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_land_number__invalid") && result.push(i18n.t("errors.landNumberInvalid"));
    return result;
  },

  projectUnitBuildingStatusErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_status__invalid") && result.push(i18n.t("errors.buildingStatusInvalid"));
    return result;
  },

  projectUnitTotalPriceErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_total_price__invalid") && result.push(i18n.t("errors.landTotalPriceInvalid"));
    return result;
  },

  projectUnitLandAnnualRentErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_total_annual_rent__invalid") && result.push(i18n.t("errors.landAnnualRentInvalid"));
    return result;
  },

  projectUnitAgeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_age__invalid") && result.push(i18n.t("errors.ageInvalid"));
    return result;
  },

  projectUnitPurposeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_usage__invalid") && result.push(i18n.t("errors.purposeInvalid"));
    return result;
  },

  projectUnitFaceErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_property_face__invalid") && result.push(i18n.t("errors.faceInvalid"));
    return result;
  },

  projectUnitStreetWidthErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_street_width__invalid") && result.push(i18n.t("errors.streetWidthInvalid"));
    return result;
  },

  projectUnitGuaranteesErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_guarantees__required") && result.push(i18n.t("errors.guaranteesRequired"));
    errors.find((item) => item === "nhc_guarantees__invalid") && result.push(i18n.t("errors.guaranteesInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
