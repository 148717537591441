import { i18n } from "@/plugins/i18n";

export default (field) => {
  return (state) => {
    if (!state.errors?.length) return []
    const fieldArray = Array.isArray(field) ? field : [field]
    const findErrors = fieldArray.map(field => state.errors.find?.(error => error.startsWith(`${field}__`)))
    const fitError = findErrors.find?.(error => error)
    return (fitError && [i18n.t(`errors.${fitError}`)]) || []
  }
}
