// import agencyApi from "@/api/agency";
// import { i18n } from "@/plugins/i18n";
import axios from "@/plugins/axios";
import translateErrors from "@/store/utils/translateErrors";

const state = {
  isLoading: false,
  isLoadingAction: false,
  isLoadingGeneral: false,
  isLoadingUpdateDomain: false,
  isLoadingUpdateColors: false,
  isLoadingHero: false,
  isLoadingLogo: false,
  isLoadingBanner: false,
  isLoadingFavicon: false,
  isLoadingDomainVerify: false,
  site: {},
  errors: [],
};

const mutations = {
  getSiteStart(state) {
    state.isLoading = true;
    state.site = {};
    state.errors = [];
  },
  getSiteSuccess(state, site) {
    state.isLoading = false;
    state.site = site;
  },
  getSiteFailed(state, errors) {
    state.isLoading = false;
    state.errors = errors;
  },

  sendActionStart(state) {
    state.isLoadingAction = true;
    state.errors = [];
  },
  sendActionSuccess(state, site) {
    state.isLoadingAction = false;
    state.site = site;
  },
  sendActionFailed(state, errors) {
    state.isLoadingAction = false;
    state.errors = errors;
  },

  updateSiteGeneralStart(state) {
    state.isLoadingGeneral = true;
    state.errors = [];
  },

  updateSiteGeneralSuccess(state, site) {
    state.isLoadingGeneral = false;
    state.site = site;
  },

  updateSiteGeneralFailed(state, errors) {
    state.isLoadingGeneral = false;
    state.errors = errors;
  },

  updateDomainStart(state) {
    state.isLoadingUpdateDomain = true;
    state.errors = [];
  },

  updateDomainSuccess(state, site) {
    state.isLoadingUpdateDomain = false;
    state.site = site;
  },

  updateDomainFailed(state, errors) {
    state.isLoadingUpdateDomain = false;
    state.errors = errors;
  },

  updateUpdateColorsStart(state) {
    state.isLoadingUpdateColors = true;
    state.errors = [];
  },

  updateUpdateColorsSuccess(state, site) {
    state.isLoadingUpdateColors = false;
    state.site = site;
  },

  updateUpdateColorsFailed(state, errors) {
    state.isLoadingUpdateColors = false;
    state.errors = errors;
  },

  updateHeroStart(state) {
    state.isLoadingHero = true;
    state.errors = [];
  },

  updateHeroSuccess(state, site) {
    state.isLoadingHero = false;
    state.site = site;
  },

  updateHeroFailed(state, errors) {
    state.isLoadingHero = false;
    state.errors = errors;
  },

  updateLogoStart(state) {
    state.isLoadingLogo = true;
    state.errors = [];
  },

  updateLogoSuccess(state, site) {
    state.isLoadingLogo = false;
    state.site = site;
  },

  updateLogoFailed(state, errors) {
    state.isLoadingLogo = false;
    state.errors = errors;
  },

  updateBannerStart(state) {
    state.isLoadingBanner = true;
    state.errors = [];
  },

  updateBannerSuccess(state, site) {
    state.isLoadingBanner = false;
    state.site = site;
  },

  updateBannerFailed(state, errors) {
    state.isLoadingBanner = false;
    state.errors = errors;
  },

  updateFaviconStart(state) {
    state.isLoadingFavicon = true;
    state.errors = [];
  },

  updateFaviconSuccess(state, site) {
    state.isLoadingFavicon = false;
    state.site = site;
  },

  updateFaviconFailed(state, errors) {
    state.isLoadingFavicon = false;
    state.errors = errors;
  },

  additionalDomainVerifyStart(state) {
    state.isLoadingDomainVerify = true;
    state.errors = [];
  },

  additionalDomainVerifySuccess(state, site) {
    state.isLoadingDomainVerify = false;
    state.site = site;
  },

  additionalDomainVerifyFailed(state, errors) {
    state.isLoadingDomainVerify = false;
    state.errors = errors;
  },

};

const actions = {
  async getSite(context) {
    try {
      context.commit("getSiteStart");

      const res = await axios.get("/agency/api/v1/site");
      context.commit("getSiteSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getSiteFailed", e.response?.data.error);
      return false;
    }
  },

  async sendAction(context, action) {
    try {
      context.commit("sendActionStart");

      const res = await axios.post("/agency/api/v1/site/action", {
        action: action,
      });
      context.commit("sendActionSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("sendActionFailed", e.response?.data.error);
      return false;
    }
  },

  async updateSiteGeneral(context, data) {
    try {
      context.commit("updateSiteGeneralStart");

      const res = await axios.put("/agency/api/v1/site/general", data);
      context.commit("updateSiteGeneralSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateSiteGeneralFailed", e.response?.data.error);
      return false;
    }
  },

  async updateDomain(context, data) {
    try {
      context.commit("updateDomainStart");

      const res = await axios.put("/agency/api/v1/site/domain", data);
      context.commit("updateDomainSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateDomainFailed", e.response?.data.error);
      return false;
    }
  },

  async updateUpdateColors(context, data) {
    try {
      context.commit("updateUpdateColorsStart");

      const res = await axios.put("/agency/api/v1/site/colors", data);
      context.commit("updateUpdateColorsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateUpdateColorsFailed", e.response?.data.error);
      return false;
    }
  },

  async updateHero(context, data) {
    try {
      context.commit("updateHeroStart");

      const res = await axios.post("/agency/api/v1/site/hero", data, {headers: {'Content-Type': 'multipart/form-data'}});
      context.commit("updateHeroSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateHeroFailed", e.response?.data.error);
      return false;
    }
  },

  async updateLogo(context, data) {
    try {
      context.commit("updateLogoStart");

      const res = await axios.post("/agency/api/v1/site/logo", data, {headers: {'Content-Type': 'multipart/form-data'}});
      context.commit("updateLogoSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateLogoFailed", e.response?.data.error);
      return false;
    }
  },

  async updateBanner(context, data) {
    try {
      context.commit("updateBannerStart");

      const res = await axios.post("/agency/api/v1/site/social-media-banner", data, {headers: {'Content-Type': 'multipart/form-data'}});
      context.commit("updateBannerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateBannerFailed", e.response?.data.error);
      return false;
    }
  },

  async updateFavicon(context, data) {
    try {
      context.commit("updateFaviconStart");
      console.log(data)

      const res = await axios.patch("/agency/api/v1/site/favicon", data, {headers: {'Content-Type': 'multipart/form-data'}});
      context.commit("updateFaviconSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateFaviconFailed", e.response?.data.error);
      return false;
    }
  },

  async additionalDomainVerify(context) {
    try {
      context.commit("additionalDomainVerifyStart");

      const res = await axios.post("/agency/api/v1/site/additional-domain-verify");
      context.commit("additionalDomainVerifySuccess", res.data);
      return true;
    } catch (e) {
      context.commit("additionalDomainVerifyFailed", e.response?.data.error);
      return false;
    }
  }
};

const getters = {
  shortDescriptionArErrors: translateErrors('short_description_ar'),
  shortDescriptionEnErrors: translateErrors('short_description_en'),
  domainTypeErrors: translateErrors('type'),
  subdomainErrors: translateErrors('subdomain'),
  additionalDomainErrors: translateErrors('additional_domain'),
  primaryColorErrors: translateErrors('primary_color'),
  primaryTextColorErrors: translateErrors('primary_text_color'),
  secondaryColorErrors: translateErrors('secondary_color'),
  secondaryTextColorErrors: translateErrors('secondary_text_color'),
  favicon16Errors: translateErrors('favicon_16'),
  favicon32Errors: translateErrors('favicon_32'),
  favicon64Errors: translateErrors('favicon_64'),
  favicon180Errors: translateErrors('favicon_180'),
  favicon192Errors: translateErrors('favicon_192'),
  faviconSvgErrors: translateErrors('favicon_svg'),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
