import axios from "axios";
import { getItem, setItem } from "@/helpers/persistanceStorage";
import { app } from "@/main.js";
import { i18n } from "@/plugins/i18n";

const _axios = axios.create({});

_axios.interceptors.request.use((config) => {
  const token = getItem("jwt_token");
  const authorizationToken = token ? `Bearer ${token}` : "";

  config.headers.Authorization = authorizationToken;
  config.headers['Accept-Language'] = i18n.locale;

  return config;
});

_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status >= 500) {
        app.$notify({
              horizontalAlign: "center",
              verticalAlign: "top",
              message: i18n.t('errors.somethingWrong'),
              type: 'error'
            })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('action__not_allowed')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.common_action__not_allowed'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('service__not_available')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.service__not_available'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('property__fetch_failed')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.property__fetch_failed'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('property__type_not_supported')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.property__type_not_supported'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('project_unit__fetch_failed')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.project_unit__fetch_failed'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('project_unit__type_not_supported')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.project_unit__type_not_supported'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('dns_record__not_created')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.dns_record__not_created'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('hero__invalid')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.hero__invalid'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('logo__invalid')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.logo__invalid'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('social_media_banner__invalid')) {
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.social_media_banner__invalid'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.data.error.includes('property__not_rega_advertised')) {
        console.log(err.response)
        app.$notify({
          horizontalAlign: "center",
          verticalAlign: "top",
          message: i18n.t('errors.property__not_rega_advertised'),
          type: 'error'
        })
        return Promise.reject(err);
      }

      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        return axios
          .post(
            "/agency/api/v1/refresh",
            {},
            {
              headers: {
                Authorization: `Bearer ${getItem("jwt_token")}`,
              },
            }
          )
          .then((res) => {
            setItem("jwt_token", res.data.access_token);

            return _axios.request(err.config);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              app.$router.push("/sign-in").catch(() => {});
              sessionStorage.removeItem("jwt_token");
              app.$store.commit("setLoggedIn", false);
            }
            return Promise.reject(e);
          });
      }
    }

    return Promise.reject(err);
  }
);

export default _axios;
