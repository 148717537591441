<template>
  <v-app>
    <component :is="layout" :key="key">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  components: { EmptyLayout, MainLayout },
  data() {
    return {
      key: 1,
    };
  },
  watch: {
    lang() {
      ++this.key;
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
#app {
  * {
    font-family: "Frutiger", sans-serif !important;
  }

  .v-tab {
    letter-spacing: 0;
  }

  .arabic-black {
    font-family: "Frutiger Black", sans-serif !important;
  }

  .arabic-bold {
    font-family: "Frutiger Bold", sans-serif !important;
  }

  .arabic-light {
    font-family: "Frutiger Light", sans-serif !important;
  }

  .arabic-default {
    font-family: "Frutiger", sans-serif !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e5e5e5;
  }

  .v-data-table {
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
    thead {
      tr {
        th {
          color: #9d9d9d;
          background-color: #f4f4f4 !important;
          text-transform: uppercase;
          vertical-align: middle;
          font-size: 14px !important;

          .v-input {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &.cursor--default {
          cursor: default;
        }
      }
      td {
        font-size: 16px !important;
        vertical-align: middle;

        .v-input {
          margin: 0 !important;
          padding: 0 !important;
        }
        transition: background-color 0.2s;
        //&:hover {
        //  background-color: var(--v-gray-3-base) !important;
        //}
      }
    }
  }
}

.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.v-btn {
  letter-spacing: 0 !important;
}

.radius-50 {
  border-radius: 50% !important;
}

.radius-34 {
  border-radius: 34px !important;
}
.radius-24 {
  border-radius: 24px !important;
}
.radius-22 {
  border-radius: 22px !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-10 {
  border-radius: 10px !important;
}
.radius-8 {
  border-radius: 8px !important;
}
.radius-6 {
  border-radius: 6px !important;
}
.radius-4 {
  border-radius: 4px !important;
}
.radius-0 {
  border-radius: 0px !important;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.hidden {
  overflow: hidden !important;
}

.btn-shadow {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.mb-56 {
  margin-bottom: 56px;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-60 {
  font-size: 60px !important;
}

.v-application {
  & p {
    margin-bottom: 0 !important;
  }
}

.pointer {
  cursor: pointer;
}
.btn-text {
  text-transform: initial;
}

.interrupted {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.v-btn__loader {
  color: white !important;
}
.btn-loader--blue .v-btn__loader {
  color: #3f9ed7 !important;
}
.btn-loader--red .v-btn__loader {
  color: #b84545 !important;
}

.auth {
  max-width: 520px !important;
  width: 100%;
  margin: 0 auto;

  &__description {
    max-width: 400px;
  }
}

.rotate-30 {
  transform: rotate(30deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

label {
  width: max-content;
  display: block;
  margin-bottom: 2px !important;
}

.v-input .v-input__slot {
  border-radius: 16px !important;
}

.text-center input {
  text-align: center !important;
}

.main__wrapper {
  max-width: 1040px;
  width: 100%;
  padding: 0 70px;
  margin: 0 auto;
}

.info__section {
  border: 1px solid #e5e5e5;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  background: white;
  overflow: hidden;
}

.info__section--unset {
  overflow: unset !important;
}

.hover-ava {
  align-items: flex-end;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0.01%,
    rgba(4, 4, 4, 0.063) 66.05%,
    rgba(46, 46, 46, 0.7) 100%
  );
}

.close-btn {
  position: absolute !important;
  top: 16px;
  right: 16px;
  z-index: 2;
}

.relative {
  position: relative !important;
}

.loader--center {
  position: absolute;
  left: calc(50% + 120px);
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-application--is-rtl {
  .loader--center {
    left: initial;
    right: calc(50%);
  }
}

// .v-select__selection--comma {
//   overflow: unset !important;
// }

.info {
  &__wrapper {
    border-bottom: 1px solid #e5e5e5;
  }
}

.min100vh {
  min-height: calc(100vh - 120px);
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.type-wrapper {
  &.v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 16px !important;
    border: 1px solid #e5e5e5;
    &--active {
      border: 1px solid #3f9ed7 !important;
    }
    &::before {
      border-radius: 16px !important;
      background-color: transparent;
    }
  }
}

.radius-gray {
  border: 1px solid #cdcdcd !important;
}

.v-input--selection-controls__input {
  margin-right: 0 !important;
}

.dialog-parent .v-dialog {
  border-radius: 16px !important;
}

.v-input .v-input__slot {
  border-radius: 8px !important;
  min-height: 30px !important;
}

.v-btn {
  text-transform: none !important;
}
p {
  line-height: 150% !important;
}

.v-application {
  & .btn--download {
    border: 1px solid #e5e5e5 !important;
    border-radius: 6px !important;
  }
}

.w-100 {
  width: 100%;
}

.height-full-page {
  min-height: calc(100vh - 64px - 47px);
}

.text-700 {
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.table-footer {
  .v-pagination {
    justify-content: start !important;
    .v-pagination__item,
    .v-pagination__navigation {
      box-shadow: none;
    }
  }
}

input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.files-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px 8px;
}

.v-application .white {
  border-color: unset !important;
}
.border--gray {
  border: 1px solid #e5e5e5 !important;
}

.grid-cards {
  display: grid;
  grid-gap: 10px;
  grid-column-start: auto;
  grid-template-columns: repeat(auto-fit, minmax(290px, max-content));
  justify-items: start;
}

.hide-excess {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.basis-unset.v-list-item {
  flex-basis: unset;
}

.border--gray-1 {
  border: 1px solid #cdcdcd;
}

.delete-btn:hover,
.download-btn:hover {
  border: 1px solid var(--v-primary-base);
  transition: all 0.2s;
}

.input-loader {
  top: 50%;
  transform: translateY(-50%);
}

.border--gray {
  border: 1px solid #e5e5e5;
}

.bg-white {
  background: #ffffff;
}

.link-without-underline {
  text-decoration: none;
}

.v-application ul {
  padding-left: 0 !important;
}
.v-application--is-rtl {
  .v-application ul {
    padding-left: initial !important;
    padding-right: 0 !important;
  }
}

.files-btn {
  border: 2px dashed #3f9ed7 !important;
  border-radius: 4px !important;
}

.top-divider-line {
  border-top: 1px solid #e5e5e5;
}

.w-min-content {
  width: min-content;
}

.h-100 {
  height: 100%;
}

.v-text-field .v-input__slot {
  border-radius: 12px !important;
}
.v-text-field .v-input__control {
  border-radius: 12px !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.status-expired {
  background-color: #c5222b !important;
  color: #ffffff !important;
}
.status-created {
  background-color: #3f9ed7 !important;
  color: #ffffff !important;
}
.status-waiting {
  background-color: #d17e02 !important;
  color: #ffffff !important;
}
.status-confirmed {
  background-color: #03a500 !important;
  color: #ffffff !important;
}
.status-finished {
  background-color: #00b695 !important;
  color: #ffffff !important;
}
.status-requested {
  background: rgba(63, 158, 215, 0.2) !important;
  color: #3f9ed7 !important;
}
.status-approved {
  background: rgba(3, 165, 0, 0.2) !important;
  color: #03a500 !important;
}
.status-rejected {
  background: rgba(197, 34, 43, 0.2) !important;
  color: #c5222b !important;
}
.status-canceled {
  background: #cdcdcd !important;
  color: #717171 !important;
  border: 0.5px solid var(--gray-01, #9d9d9d) !important;
}

.cover {
  object-fit: cover;
}

.v-menu__content.menuable__content__active {
  border: 0.5px solid rgba(151, 151, 151, 0.87);
  border-radius: 4px !important;
}

.v-input.menu-shadow {
  .v-menu__content.menuable__content__active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;
  }
}

.gap-20 {
  gap: 20px;
}

.w-100 {
  width: 100%;
}

.input50 {
  .v-input {
    .v-text-field__slot {
      height: 50px;
      min-height: 50px;
    }

    .v-input__slot {
      height: 50px !important;
      min-height: 50px;
    }
  }
}

.custom-radio {
  label {
    width: max-content;
  }
}
</style>
